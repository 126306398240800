<template>
  <section class="min-w1600">
    <!--div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" @click="onChangeType('banklevel')" class="tab-item" :class="{'active': type === 'banklevel'}">{{ $t('common.infoGrade') }}</button>
        <button type="button" @click="onChangeType('userlevel')" class="tab-item" :class="{'active': type === 'userlevel'}">{{ $t('common.bonusGrade') }}</button>
      </div>
    </div-->
    <div class="main-contents-wrapper" v-if="type == 'banklevel'">
      <div class="table-wrapper" >
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <tr v-for="item in tableData" :key="item.level">
              <td class="roboto">{{ item.level }}</td>
              <td>
                <span v-if="!item.isActive">{{ item.bankCode === "DIRECT" ? $t('common.qna') : item.bankCode }}</span>
                <template v-else>
                  <select v-model="item.bankCode" @change="bankCodeChange(item)">
                    <option value="DIRECT">{{ $t('common.qna') }}</option>
                    <option v-for="bank in bankList" :key="bank.bankCode">{{ bank.bankCode }}</option>
                  </select>
                </template>
              </td>
              <td>
                <span>{{ item.bankNameKr }}({{ item.bankNameEn }})</span>
              </td>
              <td>
                <span v-if="!item.isActive">{{ item.bankAcc }}</span>
                <textarea v-model="item.bankAcc" v-if="item.isActive" ></textarea>
              </td>
              <td>
                <ui-toggle :textType="'default'" :checked="item.useYn" @setYn="setUseYn(item)" />
              </td>
              <td style="width: 10%">
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="msgModal(item)">{{ $t('table.head.qTitle2') }}</button>
                  <button v-if="!item.isActive" class="btn-innerTable btn-status-change wait" type="button" @click="updateAccInfoToggle(item)">{{ $t('button.modify') }}</button>
                  <button v-if="item.isActive" class="btn-innerTable btn-status-change wait" type="button" @click="updateAccInfo(item)">{{ $t('button.save') }}</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="main-contents-wrapper" v-if="type == 'userlevel'">
      <div class="table-wrapper">
        <table class="mainTable">
          <thead>
             <tr>
                <!--th><input type="checkbox" name="" value="" /></th-->
                <th>{{ $t('table.head.userlv') }}</th>
                <th>{{ $t('company.classname') }}</th>
                <!--<th>폴더수 제한</th> -->
                <th>{{ $t('common.firstChargeSU') }}</th>
                <th>{{ $t('common.firstChargeDay') }}</th>
                <th>{{ $t('common.ateveryCharge') }}</th>
                <th>{{ $t('common.maxpay') }}</th>
                <!-- <th>아이콘</th> -->
             </tr>
          </thead>
          <tbody>
            <tr v-for="item in userLevelList" :key="item.memLevelName">
              <!--td><input type="checkbox" name="" value="" /></td-->
              <td> {{item.memLevel}} </td>
              <td>
                <div class="status-change-btn-wrap gap-5">
                  <input type="text" name="" :placeholder="$t('company.class')" v-model="item.memLevelName">
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="updateUserLevel(item, 'memLevelName')">{{ $t('button.save') }}</button>
                </div>
              </td>
              <!--td>
                 <div class="status-change-btn-wrap gap-5">
                   <input class="w50" type="text" name="" v-model="item.folderCountLimit"> 건 이상
                   <button class="btn-innerTable btn-status-change domainsave" type="button" @click="updateUserLevel(item, 'folderCountLimit')">{{ $t('button.save') }}</button>
                 </div>
              </td-->
              <td>
                 <div class="status-change-btn-wrap gap-5">
                   <input class="w50" type="text" name="" v-model="item.fstChrgByJoinRate"> %
                   <button class="btn-innerTable btn-status-change wait" type="button" @click="updateUserLevel(item, 'fstChrgByJoinRate')">{{ $t('button.save') }}</button>
                 </div>
              </td>
              <td>
                 <div class="status-change-btn-wrap gap-5">
                   <input class="w50" type="text" name="" v-model="item.fstChrgByDailyRate"> %
                   <button class="btn-innerTable btn-status-change wait" type="button" @click="updateUserLevel(item, 'fstChrgByDailyRate')">{{ $t('button.save') }}</button>
                 </div>
              </td>
              <td>
                 <div class="status-change-btn-wrap gap-5">
                   <input class="w50" type="text" name="" v-model="item.fstChrgByEveryTimeRate"> %
                   <button class="btn-innerTable btn-status-change wait" type="button" @click="updateUserLevel(item, 'fstChrgByEveryTimeRate')">{{ $t('button.save') }}</button>
                 </div>
              </td>
              <td>
                <div class="status-change-btn-wrap gap-5">
                  <input class="w140" type="text" name="" v-model="item.bonusLimit" @input="setBonusLimit(item)">
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="updateUserLevel(item, 'bonusLimit')">{{ $t('button.save') }}</button>
                </div>
              </td>
              <!-- <td>
                 <div class="status-change-btn-wrap">
                   <button class="btn-innerTable btn-status-change wait" type="button" @click="updateAccInfoToggle(item)">{{ $t('button.modify') }}</button>
                 </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="page-contents-group">
        <article class="page-contents-wrap">
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-content info">
                  <h3>[ Comment ]</h3>
                  <ul>
                    <li><em>1.</em>{{ $t('txt.commenta') }}</li>
                    <li><em>2.</em>{{ $t('txt.commentb') }}</li>
                    <li><em>3.</em>{{ $t('txt.commentc') }}</li>
                    <li><em>4.</em>{{ $t('txt.commentd') }}</li>
                    <li><em>5.</em>{{ $t('txt.commente') }}</li>
                    <li><em>6.</em>{{ $t('txt.commentf') }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <!-- <div class="bottom-btn-wrap">
         <div>
            <button class="btn-innerTable btn-status-change warn" type="button">추가설정</button>
            <button class="btn-innerTable btn-status-change warn" type="button">수정하기</button>
         </div>
      </div> -->
      <!-- <article class="page-contents-wrap">
         <div class="sub-title">
            <h3>회원등급 정보</h3>
         </div>
         <div class="page-content">
            <div class="item-row-container">
               <div class="item-wrap">
                  <div class="item-title">등급이름</div>
                  <div class="item-content"><input class="w50" type="text" /></div>
               </div>
               <div class="item-wrap">
                  <div class="item-title">등급 아이콘(16x16)</div>
                  <div class="item-content"><input type="file" class="" /></div>
               </div>
               <div class="item-wrap">
                  <div class="item-title">{{ $t('common.sort') }}</div>
                  <div class="item-content"><input class="w50" type="text" value="2" /> 번째</div>
               </div>
            </div>
         </div>
      </article>
      <article class="page-contents-wrap">
         <div class="sub-title">
            <h3>등급별 마일리지 설정</h3>
         </div>
         <div class="page-content">
            <div class="item-row-container">
               <div class="item-wrap">
                  <div class="item-title">매일 첫충전</div>
                  <div class="item-content"><input class="w50" type="text" value="0" /> %</div>
               </div>
               <div class="item-wrap">
                  <div class="item-title">매 충전시</div>
                  <div class="item-content"><input class="w50" type="text" value="0" /> %</div>
               </div>
               <div class="item-wrap">
                  <div class="item-title">추천인 충전시</div>
                  <div class="item-content"><input class="w50" type="text" value="0" /> %</div>
               </div>
            </div>
         </div>
      </article>
      <article class="page-contents-wrap">
         <div class="sub-title">
            <h3>등급별 롤링 설정</h3>
         </div>
         <div class="page-content">
            <div class="item-row-container">
               <div class="item-wrap">
                  <div class="item-title">첫충 롤링</div>
                  <div class="item-content">
                     <input type="text" class="w50" value="" /> %
                  </div>
               </div>
               <div class="item-wrap">
                  <div class="item-title">메충 롤링</div>
                  <div class="item-content">
                     <input type="text" class="w50" value="" /> %
                  </div>
               </div>
            </div>
            <div class="item-row-container">
               <div class="item-wrap">
                  <div class="box-tip">
                     <div class="item-title">주의 :</div>
                     <div class="box">
                        PROTO > 설정 > 마일리지 메뉴에서 설정하신 아이템과 <em class="red">등급별 마일리지</em>는 합산 됩니다.
                     </div>
                  </div>
               </div>
               <div class="item-wrap">
                  <div class="box-tip">
                     <div class="item-title">예시 :</div>
                     <div class="box">
                        일반회원 <em class="red">[미적중 적립]</em> 3% + 등급별 마일리지 2% = 5%
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </article>
      <div class="center"><button type="button">등록하기</button></div> -->
    </div>

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3>{{ $t('button.titleMsg') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <textarea v-model="modalItem.msg"></textarea>
              </div>
            </div>
            <div class="modal-btns">
               <a @click="updateAccInfo(modalItem)">{{ $t('button.save') }}</a>
               <a @click="modalClose">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import uiToggle from '@/components/ui/UiToggle'
import TableHead from '@/components/main/table/Head.vue'
import { getSiteData } from '@/libs/auth-helper'
import {
  settingLvAccount,
  settingLvAccountSave,
  comBank,
  getSettingUserLevelList,
  updateSettingUserLevel
} from '@/api/setting'
import { thousand } from '@/libs/utils'

export default {
  name: 'LevelLevel',
  components: {
    TableHead,
    uiToggle
  },
  data () {
    return {
      type: 'banklevel',
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['userlv', 'bankCode', 'bankEn', 'account_msg', 'status', 'option']
      },
      siteInfo: {},
      tableData: [],
      tableDataClone: [],
      bankList: [],
      originData: {},
      updModalActive: false,
      modalItem: {},
      userLevelList: []
    }
  },
  watch: {
    async type () {
      if (this.type === 'banklevel') {
        await this.onLoadUserLevel()
      }
    }
  },
  methods: {
    setBonusLimit (item) {
      console.log(item)
      item.bonusLimit = thousand(Number(item.bonusLimit.replace(/\,/g, '')))
    },
    updateUserLevel (item, updateField) {
      const params = {
        memLevel: item.memLevel
      }
      if (updateField === 'bonusLimit') {
        params[updateField] = Number(item[updateField].replace(/\,/g, ''))
      } else {
        params[updateField] = item[updateField]
      }

      console.log(params)

      return updateSettingUserLevel(params).then(res => {
        const resultCode = res.resultCode

        if (resultCode === '0') {
          alert('정상적으로 수정되었습니다.')
          item.isActive = false
        } else {
          alert(res.resultMessage)
        }
      })
    },
    onLoadUserLevel () {
      return getSettingUserLevelList().then(res => {
        console.log(res)
        const resultCode = res.resultCode
        const data = res.data

        if (resultCode === '0') {
          const list = data.list
          this.userLevelList = list
          this.userLevelList.forEach(item => {
            item.isActive = false
            item.bonusLimit = thousand(item.bonusLimit)
          })
        }
      })
    },
    onChangeType (type) {
      this.type = type
    },
    msgModal (item) {
      // console.log(item)
      this.modalItem = item
      this.updModalActive = true
    },
    modalClose () {
      this.updModalActive = false
      this.modalItem = {}
    },
    bankCodeChange (item) {
      console.log(item)
      this.originData = item
      const keyList = ['bankNameKr', 'bankNameEn']
      if (item.bankCode === 'DIRECT') {
        for (const key of keyList) {
          item[key] = '-'
        }
      } else {
        for (const bank of this.bankList) {
          if (bank.bankCode === item.bankCode) {
            item.bankNameKr = bank.bankNameKr
            item.bankNameEn = bank.bankNameEn
          }
        }
      }
    },
    async getBankCode () {
      const req = {
        siteId: this.siteInfo.siteId
      }
      const res = await comBank(req)
      // console.log(res);
      if (res.data.list.length !== 0) {
        this.bankList = res.data.list
      }
    },
    async updateAccInfo (item) {
      console.log(item)
      const trigger = confirm('해당 계좌정보를 수정하시겠습니까?')
      if (!trigger) {
        this.setTableData()
        return
      }
      const itemClone = lodash.cloneDeep(item)
      if (itemClone.useYn) {
        itemClone.useYn = 'Y'
      } else {
        itemClone.useYn = 'N'
      }
      const req = {
        siteId: this.siteInfo.siteId,
        level: itemClone.level,
        bankCode: itemClone.bankCode,
        bankAcc: itemClone.bankAcc,
        useYn: itemClone.useYn,
        msg: itemClone.msg
      }
      const res = await settingLvAccountSave(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('수정 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('수정 실패, 다시 시도해주세요.')
      }
      this.setTableData()
    },
    updateAccInfoToggle (item) {
      item.isActive = !item.isActive
    },
    async setUseYn (item) {
      item.useYn = !item.useYn
      const itemClone = lodash.cloneDeep(item)
      if (itemClone.useYn) {
        itemClone.useYn = 'Y'
      } else {
        itemClone.useYn = 'N'
      }
      const req = {
        siteId: this.siteInfo.siteId,
        level: itemClone.level,
        bankCode: itemClone.bankCode,
        bankAcc: itemClone.bankAcc,
        useYn: itemClone.useYn,
        msg: itemClone.msg
      }
      const res = await settingLvAccountSave(req)
      if (res.resultCode === '0') {
        alert('수정 완료')
        this.setTableData()
      } else {
        alert('수정 실패, 다시 시도해주세요.')
      }
      this.setTableData()
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async getAccountData () {
      const req = {
        siteId: this.siteInfo.siteId
      }
      const res = await settingLvAccount(req)
      return await res.data
    },
    async setTableData () {
      const data = await this.getAccountData()

      if (data.list.length !== 0) {
        data.list.sort((a, b) => {
          a = a.level.toLowerCase()
          b = b.level.toLowerCase()
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })
        for (const item of data.list) {
          if (item.useYn === 'Y') {
            item.useYn = true
          } else {
            item.useYn = false
          }
          item.isActive = false
          item.isDirect = false
          if (item.bankCode === 'DIRECT') {
            item.bankNameKr = '-'
            item.bankNameEn = '-'
          }
          item.bonusLimit = thousand(item.bonusLimit)
        }
      }
      this.tableData = data.list
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    await this.getBankCode()
    await this.setTableData()
  }
}
</script>

<style scoped>
.red {color: #f50000;}
.box-tip {font-weight: bold;gap: 5px;}
.box-tip .box {background: #c2e2ff;border: 1px solid #88c7ff;padding: 2px 3px;}
.bottom-btn-wrap {display: flex;justify-content: space-between;align-items: center;}
.bottom-btn-wrap > div {display: flex;align-items: center;gap: 5px;}
.w50 {width: 50px;}
.w140 {width: 140px;}
input {border-radius: 2px;border: solid 1px #000;background-color: #fff;color: #3d3d3d;font-weight: 800;}
td:nth-child(5) {/*text-align: left;*/}
th:nth-child(5) span, td:nth-child(5) span {white-space: pre-wrap;}
td:nth-child(5) textarea {width: 100%;height: 50px;}
.item-wrap > div.item-content{
  flex-direction: column;
  align-items: flex-start;
}
.info { padding: 20px;}
.info h3 {font-size: 20px;margin-bottom: 20px;}
.info ul {margin-bottom: 20px; font-size: 15px; line-height: 1.7;}
.info ul:last-child {margin-bottom: 0px;}
.info em {margin-right: 5px; font-weight: bold;}
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #aaa;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 2px;
  background-color: #fafafa;
}
.center {text-align: center;margin: 50px 0 70px;}
.center button {
    font-size: 18px;
    width: 168px;
    height: 42px;
    color: #fff;
    background: #353535;
    border: 1px solid #353535;
    cursor: pointer;
}
.pt50 {
  padding-top: 50px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
</style>
